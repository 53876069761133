@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Code font for command displays */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Dark mode transitions */
.dark {
  color-scheme: dark;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Dark mode scrollbar */
.dark ::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.dark ::-webkit-scrollbar-thumb {
  background: #444;
}

.dark ::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Selection color */
::selection {
  background: #3b82f6;
  color: white;
}

.dark ::selection {
  background: #60a5fa;
  color: white;
}

/* Add these to your existing index.css */

/* Keyboard shortcut styles */
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1a202c;
  background-color: #f1f5f9;
  border: 1px solid #cbd5e1;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

/* Dark mode styles */
.dark kbd {
  color: #e2e8f0;
  background-color: #1e293b;
  border-color: #334155;
}

/* Prefix styles */
.prefix {
  margin-right: 0.5rem;
}

/* Spacing between multiple shortcuts */
.kbd-margin {
  margin: 0 0.25rem;
}

/* Dark mode kbd styles */
.dark kbd {
  color: #e2e8f0;
  background-color: #1e293b;
  border-color: #334155;
}

/* Transition for mode changes */
* {
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

/* Ad container styles */
.ad-container {
  min-height: 100px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  overflow: hidden;
}

/* Responsive ad container */
@media (max-width: 768px) {
  .ad-container {
    margin: 1rem 0;
  }
}

/* Hide ad container when empty */
.ad-container:empty {
  display: none;
}

/* Vignette ad styles */
#google_vignette {
  z-index: 1000;
}

/* Footer styles */
footer {
  margin-top: auto;
  width: 100%;
}

footer a {
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}